*{
  font-family: 'Malgun Gothic', 'Apple SD Gothic Neo', '돋움', '굴림', sans-serif;
}
body {
  margin: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.login-frame {
  display: flex;
  width: 100%;
  height: 100%;
  background-size: cover;
  flex-direction: column;
  overflow-x: hidden;
  background: linear-gradient(to bottom, #2a416d 5%, #3498db 30%, #ffffff 100%);
  position: relative; 
}

.login-frame img{
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  position: relative;
  width: fit-content;
  height: fit-content;
}

.login {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  position: relative;
  display: flex;
  width: fit-content;
  height: fit-content;
  border-radius: 8px;
  flex-direction: column;
  background: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  align-items: center;

}

.login input{
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 10px;
  width: 300px;
  margin-left: 15px;
  margin-right: 15px;
}
.company{
  position: relative;
  display: flex;
  padding: 0px;
  border-radius: 4px;
  margin-bottom: 5px;
  margin-right: 15px;
  margin-left: 15px;
}
.company input{
  width: fit-content;
  margin-right: 0px;
  height: fit-content;
  border-radius: 0px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.company .search{
  width: fit-content;
  margin-right: 0px;
  height: fit-content;
  border-radius: 0px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.login button {
  border-radius: 4px;
  padding: 10px;
  border: 0px;
  align-self: center;
  background-color: #689f38;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px;
}
.company button{
  border-radius: 0px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 0px;
  width: fit-content;
  font-weight: bold;
  color: white;
  background-color:rgb(96, 96, 252);
  cursor: pointer;
}
.company button:hover{
  background-color:rgb(128, 128, 253);
}
.login .exit {
  background-color: #ccc;
}
.login button:active{
  background-color: #dcedc8;
  color: black;
}

.login h3{
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal{
  position: absolute;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  background: white;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: 40%;
  padding: 0;
  padding-bottom: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
.bar{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 5px;
}
.bar button{
  background-color: transparent;
  border: 0px;
  font-size: 16px;
}
.modal input{
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 70%;
}
.modal button{
  width: 30%;
}
.modal .company{
  margin: 10px;
  border: 0px;
}
.company-table{
  position: relative;
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  overflow-y: auto;
  user-select:auto;
  cursor: pointer;
}
.head{
  background-color: #f0f6f8;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  font-weight: bold;
}
.company-table td{
  padding: 5px;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
}
.tab{
  margin: 0;
  width: 100%;
  background-color: #ccc;
  border-radius: 8px;
  margin-bottom: 20px;
}
.tab button{
  width: 50%;
  height: 100%;
  font-size: medium;
  border-radius: 0px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: white;
  box-shadow: 0px 0px 0px;
  color: black;
}